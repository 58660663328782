/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.OpenSansCondensedBold {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
}
html {
  font-size: 18px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.textContent {
  font-size: 1rem;
}
.textContent h1 {
  margin-top: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
}
.textContent h1:first-child {
  margin-bottom: 30px;
}
.lower-content {
  background: #fff;
  padding: 10px 20px 15px;
  border-radius: 4px;
  border: 1px solid #5b89a9;
}
.lower-content h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
  margin-bottom: 20px;
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
@media (min-width: 992px) {
  .outer-wrapper--homepage .main-content__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.outer-wrapper--homepage .main-content__row .col-md-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1310px) {
  .container {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #044916;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
@media (min-width: 992px) {
  .toplinks {
    margin-left: auto;
    width: 70.277%;
  }
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0 -5px;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__li:nth-child(6n + 1) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-01.png");
}
.toplinks__li:nth-child(6n + 2) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-02.png");
}
.toplinks__li:nth-child(6n + 3) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-03.png");
}
.toplinks__li:nth-child(6n + 4) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-04.png");
}
.toplinks__li:nth-child(6n + 5) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-05.png");
}
.toplinks__li:nth-child(6n + 6) .toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/toplink-06.png");
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.16rem;
  background-size: 100% 100%;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #000;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background: #f00;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.16rem;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
  color: #fff !important;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #ffc600 !important;
}
.header {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/header-bg.png");
  background-size: cover;
  background-position: center center;
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
  }
}
.header__columns {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .header__columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.header__left {
  text-align: center;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .header__left {
    width: 26.7%;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .header__banner {
    width: 70.277%;
  }
}
.header__banner #CarouselContainer {
  padding-top: 45.888%;
  overflow: hidden;
}
.header__banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.header__banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.header__banner #CarouselContainer .carousel-inner .item a img,
.header__banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.header__banner .carousel-inner {
  border: 3px solid #fff;
}
.header__banner #CarouselContainer {
  overflow: visible !important;
}
.header__banner #CarouselPrevious,
.header__banner #CarouselNext {
  position: absolute;
  top: 50%;
  width: 23px;
  height: 54px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/gallery-arrow.png");
}
.header__banner #CarouselPrevious {
  left: 0;
  -webkit-transform: translate3d(-101%, -50%, 0);
          transform: translate3d(-101%, -50%, 0);
}
.header__banner #CarouselNext {
  right: 0;
  -webkit-transform: translate3d(101%, -50%, 0) scaleX(-1);
          transform: translate3d(101%, -50%, 0) scaleX(-1);
}
.header__logo {
  display: block;
}
@media (min-width: 992px) {
  .header__logo {
    margin-top: -20%;
  }
}
.header__phone {
  display: block;
  margin: 2% auto 0;
}
.header__call {
  margin: 5% auto 0;
  max-width: 60% !important;
}
@media (max-width: 767px) {
  .header__call {
    max-width: 100% !important;
    width: 274px;
    height: 32px;
  }
}
.header__delivery {
  display: block;
  margin: 5% auto 0;
}
.header__badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5% auto 0;
}
.header__badge {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 2px;
}
.header__badge--bcn {
  width: 50%;
}
@media (max-width: 767px) {
  .header__badge--bcn {
    width: auto;
  }
  .header__badge--bcn img {
    width: 250px;
    height: 70px;
  }
}
.header__badge--tipe {
  width: 17%;
}
@media (max-width: 767px) {
  .header__badge--tipe {
    width: 60px;
    height: 62px;
  }
}
.header__badge--biha {
  width: 15%;
}
@media (max-width: 767px) {
  .header__badge--biha {
    width: 60px;
    height: 65px;
  }
}
.main-content__wrapper {
  padding: 50px 0 100px;
}
.main-content__left > * {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
  font-size: 2.44rem;
  text-align: center;
  margin: 0;
}
@media (max-width: 767px) {
  .main-content__left > * {
    font-size: 1.8rem;
  }
}
.main-content__right img {
  -ms-flex-item-align: center;
      align-self: center;
}
.home-services {
  background-color: #044916;
  background: -webkit-gradient(linear, left top, left bottom, from(#044916), to(#76ae15));
  background: linear-gradient(to bottom, #044916, #76ae15);
  padding: 120px 0 100px;
}
.featured-service__panel {
  background: #fff;
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 34% 15px 15px;
}
@media (min-width: 992px) {
  .featured-service__panel {
    padding: 21% 20px 20px;
  }
}
@media (max-width: 767px) {
  .featured-service__panel {
    padding: 20% 15px 15px;
    margin-bottom: 34%;
  }
}
.featured-service__image {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.featured-service__description {
  margin-bottom: 20px;
}
.featured-service__link {
  background-color: #0089fe;
  background: -webkit-gradient(linear, left top, left bottom, from(#0089fe), to(#01569d));
  background: linear-gradient(to bottom, #0089fe, #01569d);
  border-radius: 5px;
  color: #fff;
  padding: 12px 20px;
  display: inline-block;
  margin-top: auto;
  line-height: 1;
  border: 1px solid transparent;
  height: auto;
  width: auto;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.featured-service__link:hover {
  color: #fff111;
}
@media (max-width: 767px) {
  .featured-service__col:last-child .featured-service__panel {
    margin-bottom: 30px;
  }
}
.weather-social__panel {
  margin: 37px auto 0;
  position: relative;
  max-width: 368px !important;
}
.weather-social__panel--0:after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 33.3333%;
  padding-top: 33.3333%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/weather-sun.png");
  background-size: 100% 100%;
}
.weather-social__col {
  text-align: center;
}
.page-listings {
  margin-top: 40px;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.page-listings__wrapper {
  background-color: #017ae2;
  background: -webkit-gradient(linear, left top, left bottom, from(#017ae2), to(#77bbe7));
  background: linear-gradient(to bottom, #017ae2, #77bbe7);
  padding-bottom: 50px;
}
.page-listings__intro {
  margin-top: 40px;
  color: #fff;
  text-align: center;
  font-size: 1rem;
}
.page-listings__intro h1,
.page-listings__intro h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
  font-size: 2.75rem;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .page-listings__intro h1,
  .page-listings__intro h2 {
    font-size: 1.8rem;
  }
}
.bubbles {
  height: 50px;
  background-size: auto 100%;
  background-position: -2px 0px;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
@media (min-width: 1200px) {
  .bubbles {
    background-size: 101% auto;
  }
}
.bubbles--blue {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/top-bubbles-blue.svg");
}
.bubbles--white {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/top-bubbles-white.svg");
}
.bubbles--green {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/top-bubbles-green.svg");
}
.castlePanel {
  width: 100%;
  padding: 10px 10px 20px;
  border: 1px solid #5b89a9;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto 30px;
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
}
.castlePanel .castleCheckBook {
  margin-top: 10px;
  height: auto;
  background-color: #7ccfe8;
  background: -webkit-gradient(linear, left top, left bottom, from(#7ccfe8), to(#278fc5));
  background: linear-gradient(to bottom, #7ccfe8, #278fc5);
  border-radius: 8px;
  border: 1px solid #3aa0e0;
  display: inline-block;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  padding: 5px 15px;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #007ae2;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.contact {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/contact-bg.png");
}
.contact .sectionTitle {
  display: none;
}
.contact .booking-form {
  margin: 0;
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.contact .innerColumn {
  padding: 0;
}
.contact .col-sm-6 {
  width: 100%;
}
.contact .col-sm-offset-6 {
  margin-left: 0;
}
.contact input,
.contact textarea,
.contact .control-label {
  text-align: center;
  display: block;
  border: none;
  border-radius: 0;
  height: 33px;
  width: 100%;
}
.contact .control-label {
  padding: 0;
  height: auto;
}
.contact label {
  color: #a6f490;
  padding: 15px 0;
  margin-bottom: 0;
  display: block;
  line-height: 1;
}
.contact .form-group {
  margin-bottom: 0;
}
.contact textarea {
  height: auto;
}
.contact__newsletter-button,
.contact input[type='button'] {
  background-color: #0089fe;
  background: -webkit-gradient(linear, left top, left bottom, from(#0089fe), to(#01569d));
  background: linear-gradient(to bottom, #0089fe, #01569d);
  border-radius: 5px;
  color: #fff;
  padding: 12px 20px;
  display: inline-block;
  margin-top: 20px;
  line-height: 1;
  border: 1px solid transparent;
  height: auto;
  width: auto;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.contact__newsletter-button:hover,
.contact input[type='button']:hover {
  color: #fff111;
}
.contact__h4 {
  color: #fff;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700 !important;
  font-size: 1.3rem;
  margin-top: 40px;
  margin-bottom: 30px;
}
.contact__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 50px;
}
.contact__social-link {
  width: 73px;
  margin: 0 9px;
}
.contact #NewsletterControl p:first-child {
  display: none;
}
@media (min-width: 992px) {
  .contact__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 992px) {
  .contact__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.contact__newsletter {
  margin-top: auto;
}
.contact__newsletter .contact__h4 {
  margin-top: 20px;
}
.footer {
  background: #0066ff;
  padding: 20px 0 50px;
  color: #fff;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
  color: #fff;
}
.footer__link:hover {
  color: #fff;
}
.footer #BCNLink {
  color: #fff;
}
.footer #BCNLink:hover {
  color: #fff;
}

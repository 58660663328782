@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@widescreen: ~"(min-width: 1330px)";
@mobileNavColor: #044916;
@castleDetailsColor: #007ae2;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/033/";

/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.OpenSansCondensedBold {
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: 700 !important;
}

html {
	font-size: 18px;
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	font-size: 1rem;

	h1 {
		margin-top: 0;
		.OpenSansCondensedBold;

		&:first-child {
			margin-bottom: 30px;
		}
	}
}

.lower-content {
	background: #fff;
	padding: 10px 20px 15px;
	border-radius: 4px;
	border: 1px solid #5b89a9;

	h2 {
		.OpenSansCondensedBold;
		margin-bottom: 20px;
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
	}

	&--homepage {
		.main-content__row {
			@media @md {
				display: flex;
			}

			.col-md-4 {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		//width: 1170px;
	}

	@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}

	@media @widescreen {
		width: 1300px;
	}

	&--content {
		flex-grow: 1;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
	@media @md {
		margin-left: auto;
		width: 70.277%;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;
		margin: 0 -5px;

		.dropdown-toggle:focus {
			text-decoration: none;
		}

		&:nth-child(6n + 1) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-01.png");
		}

		&:nth-child(6n + 2) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-02.png");
		}

		&:nth-child(6n + 3) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-03.png");
		}

		&:nth-child(6n + 4) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-04.png");
		}

		&:nth-child(6n + 5) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-05.png");
		}

		&:nth-child(6n + 6) .toplinks__link {
			background-image: url("@{cloudUrl}toplink-06.png");
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		color: #fff;
		transition: all 0.3s;
		font-size: 1.16rem;
		background-size: 100% 100%;
		.OpenSansCondensedBold;

		&:hover, &.active, &:focus {
			color: #000;
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background: #f00;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.16rem;
		.OpenSansCondensedBold;
		color: #fff !important;

		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #ffc600 !important;
		}
	}
}

.header {
	background-image: url("@{cloudUrl}header-bg.png");
	background-size: cover;
	background-position: center center;
	padding-bottom: 80px;

	@media @mobile {
		padding-top: 80px;
	}

	&__columns {
		margin-top: 20px;

		@media @md {
			display: flex;
			justify-content: space-between;
		}
	}

	&__left {
		text-align: center;
		margin-bottom: 40px;

		@media @md {
			width: 26.70%;
			margin-bottom: 0;
		}
	}

	&__banner {
		@media @md {
			width: 70.277%;
		}

		.BannerPanelSet(45.888%);

		.carousel-inner {
			border: 3px solid #fff;
		}

		#CarouselContainer {
			overflow: visible !important;
		}

		#CarouselPrevious, #CarouselNext {
			position: absolute;
			top: 50%;
			width: 23px;
			height: 54px;
			background-image: url("@{cloudUrl}gallery-arrow.png");
		}

		#CarouselPrevious {
			left: 0;
			transform: translate3d(-101%,-50%,0);
		}

		#CarouselNext {
			right: 0;
			transform: translate3d(101%,-50%,0) scaleX(-1);
		}
	}

	&__logo {
		display: block;

		@media @md {
			margin-top: -20%;
		}
	}

	&__phone {
		display: block;
		margin: 2% auto 0;
	}

	&__call {
		margin: 5% auto 0;
		max-width: 60% !important;

		@media @mobile {
			max-width: 100% !important;
			width: 274px;
			height: 32px;
		}
	}

	&__delivery {
		display: block;
		margin: 5% auto 0;
	}

	&__badges {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 5% auto 0;
	}

	&__badge {
		align-self: center;
		margin: 0 2px;

		&--bcn {
			width: 50%;

			@media @mobile {
				width: auto;

				img {
					width: 250px;
					height: 70px;
				}
			}
		}

		&--tipe {
			width: 17%;

			@media @mobile {
				width: 60px;
				height: 62px;
			}
		}

		&--biha {
			width: 15%;

			@media @mobile {
				width: 60px;
				height: 65px;
			}
		}
	}
}

.main-content {
	&__wrapper {
		padding: 50px 0 100px;
	}

	&__left {
		& > * {
			.OpenSansCondensedBold;
			font-size: 2.44rem;
			text-align: center;
			margin: 0;

			@media @mobile {
				font-size: 1.8rem;
			}
		}
	}

	&__right {
		img {
			align-self: center;
		}
	}
}

.banner {
	//.BannerPanelSet(53.406%);
}

.home-services {
	.Gradient(#044916,#76ae15);
	padding: 120px 0 100px;
}

.featured-service {
	&__panel {
		background: #fff;
		position: relative;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 34% 15px 15px;

		@media @md {
			padding: 21% 20px 20px;
		}

		@media @mobile {
			padding: 20% 15px 15px;
			margin-bottom: 34%;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate3d(-50%, -50%,0);
	}

	&__description {
		margin-bottom: 20px;
	}

	&__link {
		.Gradient(#0089fe,#01569d);
		border-radius: 5px;
		color: #fff;
		padding: 12px 20px;
		display: inline-block;
		margin-top: auto;
		line-height: 1;
		border: 1px solid transparent;
		height: auto;
		width: auto;
		transition: color 0.3s;
		.OpenSansBold;

		&:hover {
			color: #fff111;
		}
	}

	&__col:last-child {
		.featured-service__panel {
			@media @mobile {
				margin-bottom: 30px;
			}
		}
	}
}

.weather-social {
	&__panel {
		margin: 37px auto 0;
		position: relative;
		max-width: 368px !important;

		&--0 {
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				width: 33.3333%;
				padding-top: 33.3333%;
				background-image: url("@{cloudUrl}weather-sun.png");
				background-size: 100% 100%;
			}
		}

		img {
		}
	}

	&__col {
		text-align: center;
	}
}

.page-listings {
	margin-top: 40px;

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}

	&__wrapper {
		.Gradient(#017ae2,#77bbe7);
		padding-bottom: 50px;
	}

	&__intro {
		margin-top: 40px;
		color: #fff;
		text-align: center;
		font-size: 1rem;

		h1, h2 {
			.OpenSansCondensedBold;
			font-size: 2.75rem;
			margin-bottom: 30px;

			@media @mobile {
				font-size: 1.8rem;
			}
		}
	}
}

.bubbles {
	height: 50px;
	background-size: auto 100%;
	background-position: -2px 0px;
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	transform: translateY(-100%);

	@media @lg {
		background-size: 101% auto;
	}

	&--blue {
		background-image: url("@{cloudUrl}top-bubbles-blue.svg");
	}

	&--white {
		background-image: url("@{cloudUrl}top-bubbles-white.svg");
	}

	&--green {
		background-image: url("@{cloudUrl}top-bubbles-green.svg");
	}
}

.castlePanel {
	width: 100%;
	padding: 10px 10px 20px;
	border: 1px solid #5b89a9;
	border-radius: 5px;
	text-align: center;
	margin: 0 auto 30px;

	&:hover {
	}

	.SingleImagePanel {
		height: auto;
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			//font-size: 0.9rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		//font-size: 1rem;
		span.oldprice {
			//font-size: 0.65rem;
		}

		span.newprice {
			//font-size: 0.8rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		margin-top: 10px;
		height: auto;
		.Gradient(#7ccfe8,#278fc5);
		border-radius: 8px;
		border: 1px solid #3aa0e0;
		display: inline-block;
		box-shadow: 0 1px 1px rgba(0,0,0,0.5);
		padding: 5px 15px;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.DetailsTitle {
	h1, h2 {
		.OpenSansCondensedBold;
	}
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.contact {
	background-image: url("@{cloudUrl}contact-bg.png");

	.sectionTitle {
		display: none;
	}

	.booking-form {
		margin: 0;
		background-color: transparent;
		border: none;
		box-shadow: none;
	}

	.innerColumn {
		padding: 0;
	}

	.col-sm-6 {
		width: 100%;
	}

	.col-sm-offset-6 {
		margin-left: 0;
	}

	input, textarea, .control-label {
		text-align: center;
		display: block;
		border: none;
		border-radius: 0;
		height: 33px;
		width: 100%;
	}

	.control-label {
		padding: 0;
		height: auto;
	}

	label {
		color: #a6f490;
		padding: 15px 0;
		margin-bottom: 0;
		display: block;
		line-height: 1;
	}

	.form-group {
		margin-bottom: 0;
	}

	textarea {
		height: auto;
	}

	&__newsletter-button, input[type='button'] {
		.Gradient(#0089fe,#01569d);
		border-radius: 5px;
		color: #fff;
		padding: 12px 20px;
		display: inline-block;
		margin-top: 20px;
		line-height: 1;
		border: 1px solid transparent;
		height: auto;
		width: auto;
		transition: color 0.3s;
		.OpenSansBold;

		&:hover {
			color: #fff111;
		}
	}

	&__h4 {
		color: #fff;
		.OpenSansCondensedBold;
		font-size: 1.3rem;
		margin-top: 40px;
		margin-bottom: 30px;
	}

	&__social-links {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
	}

	&__social-link {
		width: 73px;
		margin: 0 9px;
	}

	#NewsletterControl p:first-child {
		display: none;
	}

	&__row {
		@media @md {
			display: flex;
		}
	}

	&__col {
		@media @md {
			display: flex;
			flex-direction: column;
		}
	}

	&__newsletter {
		margin-top: auto;

		.contact__h4 {
			margin-top: 20px;
		}
	}
}

.footer {
	background: #0066ff;
	padding: 20px 0 50px;
	color: #fff;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	#BCNLink {
		color: #fff;

		&:hover {
			color: #fff;
		}
	}
}
